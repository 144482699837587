@import '../../../components/components.scss';
.homepage_v2{
    margin: 0 60px 0 60px;
    position: relative;
    width:100%;
    @media(max-width:420px){
        margin: 0 10vw 0 10vw; 
    }
    .section {
        max-width: 1200px;
        margin: 0 auto;
        @media(max-width:540px){
            margin-top:5vw;
        }
        .content_wrapper{
            @extend .d_flex;
            justify-content: flex-start;
            min-height:calc(100vh - 140px); 
            height:600px;          
            align-items: center;
            overflow: hidden;           
            @media(max-width:1024px){
                height:550px; 
            }
            @media(max-width:920px){
                min-height:auto; 
                height:auto; 
                text-align: center;  
            }   
            .title_section{
                color:$primary_color_v2;
                font-weight: normal;
                font-family: $bitter;
                position: relative;
                z-index: 2;
                @media(max-width:920px){
                   width:100%;
                } 
                h1{
                    font-size:80px;   
                    font-weight: normal;     
                    @media(max-width:1200px){
                        font-size:70px;  
                    } 
                    @media (max-width: 1024px){
                        font-size:60px;  
                    }  
                    @media(max-width:540px){
                        font-size:10vw;  
                    }  
                    @media(max-width:420px){
                        font-size:8vw;  
                    }      
                    span{
                        display:flex;
                        position:relative;
                        strong{
                            margin-left:15px;
                            @media(max-width:920px){
                                margin-left:1.5vw;
                            } 
                        }
                        @media(max-width:920px){
                            text-align: center;  
                            justify-content: center;
                        } 
                    }
                    
                }
                p{
                    font-size:24px;
                    margin-top:25px;
                    line-height: 1.5;
                    @media(max-width:1200px){
                        font-size:18px;
                        margin-top:2vw;
                        line-height: 1.5;
                    }
                    @media (max-width: 1024px){
                        font-size:16px; 
                    } 
                    @media(max-width:540px){
                        font-size:3vw;  
                    }
                    @media(max-width:420px){
                        font-size:2.5vw;  
                    } 
                    span{
                        font-weight: bold;
                    }
                }
            }
        }
    }
    .home_image{
        .avatar_bg {
            position: absolute;
            left: 0;
            top: 0;
            min-height: 100vh;
            height:600px;
            width: 100%;
            z-index: 0;   
            @media(max-width:920px){
                position: relative;
                margin-top: 5vw;
                left: 0;
                height: 45vh;
                min-height: 300px;
            }        
            .bar_wrapper{
                perspective: 1000px;
                position: relative;
                width: 100%;
                height: 100%;
                .bar {
                    left: 58%;
                    top:45%;
                    height: 280px;
                    max-width: 840px;
                    background-size: 11px;
                    opacity: .6;
                    position: relative;
                    transform: translate(-50%, -50%);
                    @media(max-width:1200px){
                        height:20vw;
                        max-width:61vw;
                    }
                    @media (max-width: 1024px) and (max-height: 767px) {
                        top:50%; 
                    }
                    @media(max-width:920px){
                        left:50%;
                        top:40%;
                        max-width:calc(100vw - 40px);
                        height:30vw;
                    }
                    @media(max-width:420px){
                        background-size: 5px; 
                        height:20vw;
                    }
                  
                }
            }
            .avatar{
                width: 60vw;
                height: auto;
                max-width:600px;
                position: absolute;
                top: 40%;
                left: 70%;
                transform: translate(-50%,-50%);
                @media(max-width:1200px){
                    width: 46vw;
                }
                @media (max-width: 1024px) and (max-height: 767px) {
                    top:50%; 
                }
                @media(max-width:920px){
                    left:50%;
                    width:70vw;
                }
                img{
                    display: block;
                    width: 100%;
                    height: 100%;
                }
                .abs_img{
                    @extend .d_flex;
                    width: 80px;
                    height: 80px;
                    position: absolute;
                    left: 67%;
                    top: 29%;
                    transform: translate(-50%, -50%);
                    z-index: 1;
                    @media(max-width:920px){
                        width: 10vw;
                        height: 10vw;
                    }
                    img{
                        display:block;
                        width: 100%;
                        height:100%;
                        object-fit: cover;
                        animation:rotate 5s infinite linear;
                    }
                    @keyframes rotate{
                        100%{
                          transform:rotate(360deg);
                        }
                      }
                }
            }
            
        }
    }
}