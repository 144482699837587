@import '../components.scss';
.about{
    padding:170px 35px;
    position: relative;
    @media(max-width:820px){
        padding:15vw 20px;
    }
    br{
        display:none;
        @media(max-width:820px){
            display:block;
        }
    }
    .section_wrapper{
        justify-content: space-between;
        @media(max-width:820px){
            flex-direction: column;
        }
        .section_left,
        .section_right{
            width:48%;
            @media(max-width:820px){
                width:100%;
            }
        }
        .section_left{
            padding:0 50px 0 10%; 
            position:relative;
            @media(max-width:820px){
                padding:0;
            }
            .section_title{
                position:absolute;
                top: 45%;
                left: 0;
                transform: translate(-50%) rotate(-90deg);
               h3{
                @extend .subtitle;
               }
                @media(max-width:820px){
                    position:static;
                    transform: translate(0);
                    margin-bottom:7vw;
                }
            }
            .section_info{
                //width:60%;
                h2{
                    @extend .heading_2;
                }
            }
        }
        .section_right{
           .section_info{
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-left:20%;
            @media(max-width:820px){
              padding:0;
              margin-top:7vw;
            }
            p{
                @extend .body_text;
                color:#FFFFFF99;
                +p{
                    margin-top:20px;
                }
            }
           } 
        }
    }
}
