@import '../components.scss';
.hero{    
    padding:0 35px; 
    width:auto;    
    margin:0 auto;  
    position: relative; 
    overflow: hidden;
    .hero_bg{
        background-color:$primary_color_bg;
        position:absolute;
        left:0;
        right:0;
        top:0;
        bottom:0;
        z-index:3;
        height:100vh;
    }
    @media(max-width:820px){
        padding:0 20px; 
    }  
    .bg{
        z-index:4;
    }
    .section_container{
        max-width:1600px;
        position:relative; 
        height:100vh;
        margin:0 auto;
        z-index:4;
        @media(max-width:820px){
            display:flex;
            padding:0;
        }
        .box{
            width:680px;
            height:680px;
            background-color: transparent;
            background-image: linear-gradient(220deg,#2149b870 0%,#FFFFFF00 100%);
            border-radius: 40% 60% 70% 30% / 40% 50% 60% 50%;            
            animation: morph 5s linear infinite;
           // border-radius: 100%;
            position: absolute;
            left:50%;
            top:50%;
            transform:translate(-50%, -50%);
            @media(max-width:820px){
                width:80vw;
                height:80vw;
            }
          }
          .link_bottom{
            @extend .d_flex;
            justify-content: flex-start;
            align-items: center;
            position: absolute;
            bottom: 50px;
            left: 0px;
            @media(max-width:820px){
                justify-content: space-between;
                width: 100%;
                bottom: 20px;
            }
            a{
                @extend .subtitle_sm;
                color:$white;
                cursor:pointer;
                text-decoration: none;
                span{
                    text-transform: lowercase;
                }
                +a{
                    margin-left:300px;
                    @media(max-width:820px){
                        margin-left:0;
                    }
                }

            }
          }
    }

    @keyframes morph{
        0%, 100% {
        border-radius: 40% 60% 70% 30% / 40% 40% 60% 50%;
        }
        34% {
          border-radius: 70% 30% 50% 50% / 30% 30% 70% 70%;
        }
        67% {
          border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
        }
      }
    
    .section_row{
        @extend .d_flex;
        width: auto;
        min-width: 100%;
        flex-direction: column;
        position:relative;
        align-items: flex-start; 
        padding-left:16vw;
        padding-top:16vw; 
        @media(max-width:1024px){
            padding-top:20vw; 
        } 
        @media(max-width:820px){
            padding:0;
        }       
          .content_section{
            display:block;
            width: 100%;           
            .heading_container{
                max-width:580px;
                @media(max-width:820px){
                    max-width:95%;
                } 
                .smoothScroll{
                    display:inline-block;
                    cursor:pointer;
                }
                h2{
                    @extend .heading_1;
                    font-size:70px;
                    color:$white;
                    @media(max-width:820px){
                        font-size:11vw;
                        letter-spacing: -3.8px;
                        margin-bottom:2vw;
                    } 
                }
                h3{
                    color:$white;
                    @extend .subtitle_sm;
                    margin-bottom:30px;
                    color:#FFFFFF99;
                }
                .cta{
                    &.primary{ 
                        position: relative; 
                        text-align: right;
                        a{
                            cursor:pointer;
                            display: inline-block;
                            position: relative;
                            padding: 18px;
                            font-weight: 600;
                            @extend .subtitle;
                            text-transform: uppercase;  
                            color: $white; 
                            transition: all .5s ease;
                            text-decoration: none;
                            &:before{
                                content: '';
                                width: 50px;
                                height: 50px;
                                background: $primary_color;
                                border-radius: 50%;
                                top:0;
                                left: 0;
                                position: absolute;
                                z-index: -1;
                                transition: all .5s ease;
                            }
                            &:hover{
                                color:$white;
                                &:before {
                                    left: 0;
                                    width: 100%;
                                    border-radius: 30px;
                                }
                            }
                        }
                    }
                }
            }
          }
          .bio,
          .social{
            position:absolute;
            &.left{
                top: 290px;
                left: -190px;
                transform: rotate(-90deg);
                @media(max-width:820px){
                    position: static;
                    transform: rotate(0);
                    margin-top:7vw;
                }
            }
            &.right{
                top: 350px;
                right: -260px;
                transform: rotate(-90deg);
                @media(max-width:1180px){
                    right:-205px;
                }
                ul{
                    list-style: none;
                    @extend .d_flex;
                    color:$white;
                    li{
                        @extend .subtitle_sm;
                        cursor: pointer;
                        +li{
                            margin-left:50px;
                        }
                        a{
                            text-decoration: none;
                            color: inherit;
                            &.colored{
                                color:$warning_color;
                            }
                        }
                    }
                }
            }
            .heading_secondary{
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 3.5px;
                font-family:$incon;
                color:$white;
                @media(max-width:820px){
                    letter-spacing: 1.5px;
                }
            }
          }
          
    }
}
