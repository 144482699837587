@import "../../components/components.scss";
body{
    cursor:auto!important;
}
.home_v2{
    display:block;
    width:100%;
    min-height:100vh;
    min-height:100svh;
    background-color:$white;
    padding:20px;
    .pagewrap{
        background-color: $primary_color_bg_v2;
        min-height: calc(100vh - 40px);
        position:relative;
        width:100%;
        overflow: hidden;
        @extend .d_flex;
    }

    .mainnav{
        width:200px;
        position: fixed;
        top:60px;
        left:65px;
        z-index: 99;
        font-family: $bitter;
        @extend .d_flex;
        justify-content: flex-start;
        @media(max-width:420px){
            width:40vw;
            top:10vw;
            left:10vw;
        }
        .logo_wrapper{           
            a{
                color:$primary_color_v2;
                font-size:40px;
                text-decoration: none;
                @media(max-width:420px){
                    font-size:10vw;
                }
                span{
                    font-weight: 600;
                }
            }
        }
        .menu_wrapper{
            position:relative;
            margin-left:25px;
            @media(max-width:420px){
                margin-left:6vw;
            }
            span{
                font-size:14px;
                text-transform: uppercase;
                font-family: $popins;
                font-weight: 600;
                cursor: pointer;
                letter-spacing: 1px;
                @media(max-width:420px){
                    font-size:3.5vw;
                }
            }
            &:before{
                content: "";
                display: inline-block;
                width: 1px;
                height: 26px;
                margin-right: 16px;
                background: $color_disabled;
                vertical-align: middle;
                @media(max-width:420px){
                    height:6.2vw;
                    margin-right:3.8vw;
                }
            }
        }
    }
    .hire_me{
        position: fixed;
        width:100px;
        height: 26px;
        top:60px;
        right:65px;
        z-index: 99;
        @extend .d_flex;
        font-size:14px;
        cursor: pointer;
        font-family: $popins;
        letter-spacing: 1px;
        @media(max-width:420px){
            width:20vw;
            height:6vw;
            top:13vw;
            right:9vw;
            font-size:3.5vw;
        }
        .fa{
            margin-right:10px;
            font-size:24px;
            position: absolute;
            left: -20px;
            top: 50%;
            transform: translateY(-50%);
            @media(max-width:420px){
                margin-right:2.5vw;
                font-size:5.8vw;
                left: -6vw;
                top: 44%;
            }
        }
        span{
            transition: all .12s cubic-bezier(.25,.46,.45,.94);
            &:hover{
                letter-spacing: 4px;
            }
        }
    }
    .copyright{
        position: fixed;
        font-size:18px;
        transform-origin: bottom left;
        transform: rotate(-90deg);
        bottom: 84px;
        left: 55px;
        text-align: left;
        color:$color_disabled;
        z-index: 99;
        @media(max-width:420px){
            font-size:3vw;  
            bottom:10vw;
            left:14vw;
        } 
        &:before {
            content: "";
            display: inline-block;
            vertical-align: middle;
            margin-right: 15px;
            height: 1px;
            width: 25px;
            background: $color_disabled;
        }
    }
}