@import '../components.scss';
.navigation__overlay{
    width:100%;
    height:100vh;
    margin:auto;
    position: fixed;
    top:0;
    z-index:500;
    visibility:hidden;
    overflow:hidden;
    span{
      background-color:#000;
      width:100%;
      height:25%;
      position:relative;
      display:block;
      transform:translateX(-100%);
      transition:all .5s cubic-bezier(.77,0,.175,1);
      &:nth-child(4){
        transition-delay: 0s;
      }
      &:nth-child(3){
        transition-delay: 0.1s;
      }
      &:nth-child(2){
        transition-delay: 0.2s;
      }
      &:nth-child(1){
        transition-delay: 0.3s;
      }
    }
    &.active{
      visibility:visible;
      span{
        transform:translateX(0);
      }      
    }
    .closeMenu{
      position:absolute;
      width:45px;
      height:45px;
      z-index:5;
      border:2px solid $white;
      border-radius: 100%;
      right:10px;
      @extend .d_flex;
      font-size:22px;
      cursor:pointer;
      transition:top 1s cubic-bezier(.77,0,.175,1);
      top:-100px;
      span{
        width:20px;
        height:2px;
        background-color: $white;
        position: absolute;
        top:50%;
        left:50%;        
        &:nth-child(1){
          transform:translate(-50%,-50%) rotate(45deg);
          transform-origin: center;
        }
        &:nth-child(2){
          transform:translate(-50%,-50%) rotate(-45deg);
          transform-origin: center;
        }
      }
      &.active{
        top:10px;
      }
    }
  }
  .navigation__items{
    position:absolute;
    top:0;
    width:100%;
    height:100vh;
    display:flex;
    justify-content: center;
    align-items: center;
    .navigation__container{
      .navigation__link{
        overflow:hidden;
        a{
          display:block;
          text-align: center;
          text-decoration:none;
          font-size:40px;
          font-family: $dmSans;
          font-weight: bold;
          position:relative;
          line-height:1.5;
          text-transform: lowercase;
          padding-bottom:30px;
          color:rgba(#ffffff, .5);
          transition:transform 1s cubic-bezier(.4,0,.2,1);
          transform:translateY(100%);
          text-transform: uppercase;
          letter-spacing: 2px;
        }
      }
      &.active{
        .navigation__link{
          a{
            transform:translateY(0);
            &.active{
              color:$warning_color;
              &:after{
                content:attr(data-number);
                position:absolute;
                color:#fff;
                top:0;
                left:0;
                right:0;
                opacity:.2;
                letter-spacing: 0;
                transition:transform 1s cubic-bezier(.4,0,.2,1);
                transform:scale(2);
              }
            }
            &:hover{
              color:$warning_color;
              &:after{
                content:attr(data-number);
                position:absolute;
                color:$warning_color;
                top:0;
                left:0;
                right:0;
                opacity:.2;
                letter-spacing: 0;
                transition:transform 1s cubic-bezier(.4,0,.2,1);
                transform:scale(2);
              }
            }
          }
          &:nth-child(1){
            a{
              transition-delay: .6s!important;
            }
          }
          &:nth-child(2){
            a{
              transition-delay: .7s!important;
            }
          }
          &:nth-child(3){
            a{
              transition-delay: .8s!important;
            }
          }
          &:nth-child(4){
            a{
              transition-delay: .9s!important;
            }
          }
          &:nth-child(5){
            a{
              transition-delay: 1s!important;
            }
          }
          &:nth-child(6){
            a{
              transition-delay: 1.1s!important;
            }
          }
          &:nth-child(7){
            a{
              transition-delay: 1.2s!important;
            }
          }
        }
      }
    }
  }

  .copyright-wrapper{
    text-align:Center;
    position:absolute;
    left:0;
    right:0;
    bottom:20px;
    overflow:hidden;
    .copyright{
      text-transform: capitalize;
      font-size:12px;
      color:#8f8e8e;
      transition:transform 2s cubic-bezier(.77,0,.175,1);
      transform:translateY(100%);
      letter-spacing: 1.2px;    
    }
    &.active{
      .copyright{
        transform: translateY(0);
      }
    }
  }

