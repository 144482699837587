@import '../components.scss';
.skill{
    padding:170px 35px;
    position: relative;
    @media(max-width:820px){
        padding:15vw 20px;
    }
    .section_wrapper{
        justify-content: space-between;
        @media(max-width:820px){
            flex-direction: column;
        }
        .section_left,
        .section_right{
            width:50%;
            @media(max-width:820px){
                width:100%;
            }
        }
        .section_left{
            padding:0 50px 0 10%; 
            position:relative;
            @media(max-width:820px){
                padding:0;
            }
            .section_title{
                position:absolute;
                top: 45%;
                left: 0;
                transform: translate(-50%) rotate(-90deg);
                @media(max-width:820px){
                    position:static;
                    transform: translate(0);
                    margin-bottom:7vw;
                }
               h3{
                @extend .subtitle;
               }
            }
            .section_info{
                width:60%;
                @media(max-width:820px){
                   width:100%;
                    margin-bottom:7vw;
                }
                ul{
                    list-style: none;
                    padding-left:30%;
                    @media(max-width:820px){
                        padding-left:0;
                     }
                    li{
                        cursor:pointer;
                        @extend .body_text;
                        color:#FFFFFF99;
                        +li{
                            margin-top:8px;
                        }
                    }
                }
            }
        }
        .section_right{
            h3{
                @extend .heading_4;
                margin-bottom:25px;
                line-height: 1.5;
            }
            p{
                @extend .body_text;
                color:#FFFFFF99;
            }
        }
    }
}
