@import '../components.scss';
.bannerAddtionalBg{
    background:$black;
    position:absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    height:100%;  
    z-index:2;  
}
.header{
    @extend .d_flex;
    position:absolute;
    top:0;
    left:0;
    right:0;
    z-index:9;
    .section_container{
        margin-top: 40px;
        margin-bottom: 0;
        @media(max-width:820px){
            margin-top: 20px;
        }
        .section_wrapper{
            &.jcsb{
                justify-content: space-between;
            }
            .logo{
                font-family: $dmSans;
                font-size:34px;
                color:$white;
                text-transform: uppercase;
                text-decoration: none;
                span{
                    color:$warning_color;
                    font-weight: 800;
                }
            }
            .menu{
                &.right{
                    @extend .d_flex;
                    flex-direction: column;
                    align-items: flex-end;
                    width:35px;
                    height:35px;
                    cursor:pointer;
                    span{
                        width:25px;
                        height:2px;
                        background-color:$white;
                        position:relative;
                        transition: all .3s ease;
                        top:0;
                        &+span{
                            margin-top:6px;
                            width:18px;
                        }                        
                    }
                    &:hover{
                        span{
                            &:nth-child(1){
                                top:-5px;
                            }
                        }                        
                    }
                }
            }
        }
    }
}