@import '../components.scss';
.project{
    padding:170px 35px;
    position: relative;    
    @media(max-width:820px){
        padding:15vw 20px;
    }
    .section_wrapper{
        justify-content: space-between;
        @media(max-width:820px){
            flex-direction: column;
        }
        .section_left,
        .section_right{
            width:50%;            
            @media(max-width:820px){
                width:100%;
            }
        }
        .section_left{
            padding:0 50px 0 10%; 
            position:relative;
            @media(max-width:820px){
                padding:0;
            }
            .section_title{
                position:absolute;
                top: 45%;
                left: 0;
                transform: translate(-50%) rotate(-90deg);
                @media(max-width:820px){
                    position:static;
                    transform: translate(0);
                    margin-bottom:7vw;
                }
               h3{
                @extend .subtitle;
                color:#000;
               }
            }
            .section_info{
                width:60%;
                @media(max-width:820px){
                    width:100%;
                }
                h2{
                    @extend .heading_2;
                    margin-bottom:20px;
                }
                p{
                    @extend .body_text;
                    color:$accent;
                }
            }
        }
        .section_right{   
            overflow: hidden;        
            img{
                display:block;
                width:100%;
            }
            @media(max-width:820px){
                margin-top:7vw;
              }
        }
    }
    .custome_nav{
        @extend .d_flex;
        width:60%;
        justify-content: flex-end;
        margin-top:15px;
        @media(max-width:820px){
            width: 100%;
            margin-top:7vw;
        }
        .slide{
            width:50px;
            height:48px;
            @extend .d_flex;
            background-color:$accent;
            font-size:16px;
            cursor:pointer;           
            +.slide{
                margin-left:15px;
            }
        }
    }
}
