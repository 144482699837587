@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@400;500;600;700;800&family=Poppins:wght@400;600&display=swap');
$dmSans:'DM Sans', sans-serif;
$incon:'Inconsolata', monospace;
$bitter: 'Bitter', serif;
$popins: 'Poppins', sans-serif;
$primary_color_bg:#132a60;
$primary_color:#0032ff;
$secondary_color:#527ceb;
$success_color:#28a745;
$info_color:#17a2b8;
$warning_color:#ffc107;
$danger_color:#dc3545;
$black:#000;
$white:#fff;
$accent:#b1b1b3;
//v2
$primary_color_bg_v2:#F4F9FC;
$primary_color_v2:#0F1B61;
$color_disabled:#919795;
//common
.d_flex{
    display:flex;
    justify-content: center;
    align-items: center;
}
//typo
.heading_1{
    font-family:$dmSans;
    font-weight:700;
    font-size:80px;
    letter-spacing: -3.2px;
    @media(max-width:1180px){
        font-size:44px;
    }
}
.heading_2{
    font-family:$dmSans;
    font-weight:700;
    font-size:45px;
    letter-spacing: -1.8px;
    @media(max-width:1180px){
        font-size:30px;
    }
}
.heading_3{
    font-family:$dmSans;
    font-weight:700;
    font-size:35px;
    letter-spacing: -1.4px;
    @media(max-width:1180px){
        font-size:27px;
    }
}
.heading_4{
    font-family:$dmSans;
    font-weight:700;
    font-size:25px;
    letter-spacing: -.75px;
    @media(max-width:1180px){
        font-size:18px;
    }
}
.title_sm{
    font-family:$dmSans;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -.6px;
    @media(max-width:1180px){
        font-size:18px;
    }
}
.subtitle{
    font-size: 15px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 3.75px;
    color:#FFFFFF99;
    font-family: $incon;
    @media(max-width:1180px){
        font-size:13px;
    }
}
.subtitle_sm{
    font-family:$incon;
    font-size: 16px;
    text-transform: capitalize;
    font-style: normal;
    letter-spacing: -.48px;
    @media(max-width:1180px){
        font-size:14px;
    }
}
.body_text{
    font-family:$incon;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -.48px;
    line-height: 1.5;
    @media(max-width:1280px){
        font-size:14px;
    }
}



