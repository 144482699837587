@import '../components.scss';
footer{
    padding:0 35px;
    position: relative;
    @media(max-width:820px){
        padding:0 20px;
    }
    .section_wrapper{
        padding:30px 0;
        justify-content: space-between;
        @media(max-width:820px){
            flex-direction: column;
        }
        .copyright{
            @extend .body_text; 
            color:#FFFFFF99; 
            font-size:14px;
            @media(max-width:820px){
                margin-bottom:7vw;
            }          
        }
        ul{
            list-style: none;
            @extend .d_flex;
            li{
                color:#FFFFFF99; 
                cursor: pointer;
                a{
                    color:#FFFFFF99; 
                    text-decoration: none;
                    height:30px;
                    width:30px;
                    @extend .d_flex;
                    font-size:14px;
                }
                +li{
                    margin-left:40px;
                }
            }
        }
    }
}
@media(max-width:1024px){
    .scrollbar-track{
        &.scrollbar-track-y{
            &.show{
                display:none!important;
            }
        }
    }
}