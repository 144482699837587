@import '../components.scss';

.career{
    padding:170px 35px;
    position: relative;
    @media(max-width:820px){
        padding:15vw 20px;
    }
    .section_wrapper{
        justify-content: space-between; 
        @media(max-width:820px){
            flex-direction: column;
        }       
        .item{            
            position:relative;
            @extend .d_flex;
            justify-content: space-between;
            gap:60px;
            @media(max-width:820px){
                flex-direction: column;
            }   
            .section_title{
                position:relative;
                width:11.5vw;
                @extend .d_flex;
                justify-content: flex-start;
                @media(max-width:820px){
                    width:100%;
                } 
               h3{                
                transform: translate(-50%) rotate(-90deg);
                @extend .subtitle;
                color:#000;
                @media(max-width:820px){
                    transform: translate(0);
                } 
               }
            }
            .section_info{
                flex:1;                
                h2{
                    @extend .heading_3;
                    margin-bottom:15px;
                    line-height: 1.5;
                    @media(max-width:1600px){
                        font-size:2vw;
                    }
                    @media(max-width:820px){
                        font-size:20px;
                    }
                }
                p{
                    @extend .body_text;
                    color:$accent;
                }
                h3{
                    @extend .subtitle_sm;
                    color:$primary_color;
                    margin-bottom:20px;
                    span{
                        color:$accent;
                        font-weight: 400;
                    }
                }
            }
        }
        
    }
}
